<template>
  <div>
    <div :ref="keyId" style="height: 100%;width:100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import echartsLiquidfill from "echarts-liquidfill";

export default {
  name: "Wave",
  props: {
    progess: {
      default: 0.5,
    },
    total: {
      default: 0,
    },
    keyId: {
      default: "echart",
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs[this.keyId]);
      // 把配置和数据放这里
      this.chart.setOption({
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            waveAnimation: true,
            data: [
              {
                value: this.progess,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: "#46AEFF",
                  },
                },
              },
            ],
            outline: {
              show: false, //是否显示轮廓 布尔值
              borderDistance: 0, // 外部轮廓与图表的距离 数字
              itemStyle: {
                borderColor: "#1890ff", // 边框的颜色
                borderWidth: 3, // 边框的宽度
                // shadowBlur: 5 , //外部轮廓的阴影范围 一旦设置了内外都有阴影
                // shadowColor: '#000' //外部轮廓的阴影颜色
              },
            },
            itemStyle: {
              opacity: 0.9, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
              color: "#46AEFF",
            },
            backgroundStyle: {
              color: "rgba(70, 174, 255,0.38)", // 图表的背景颜色
            },
            label: {
              // 数据展示样式
              show: true,
              color: "#2EFFEA",
              insideColor: "#fff",
              fontSize: 20,
              fontWeight: 400,
              align: "center",
              baseline: "middle",
              position: "inside",
              formatter: (text) => {
                return this.total + "kg";
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
.wave {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  canvas {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
