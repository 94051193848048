import { render, staticRenderFns } from "./wave.vue?vue&type=template&id=aa8c6598&scoped=true"
import script from "./wave.vue?vue&type=script&lang=js"
export * from "./wave.vue?vue&type=script&lang=js"
import style0 from "./wave.vue?vue&type=style&index=0&id=aa8c6598&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa8c6598",
  null
  
)

export default component.exports